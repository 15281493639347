import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button",
      "style": {
        "position": "relative"
      }
    }}>{`Button`}</h1>
    <p>{`Botões expressam que alguma atividade vai acontecer quando clicados. São usados para executar uma ação e alguns exemplos incluem as ações de Adicionar, Salvar e Deletar.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Não use botões como elementos de navegação. Considere utilizar `}<strong parentName="li">{`Links`}</strong>{`, porque levam o usuário a uma nova página que não está associada com a ação.`}</li>
      <li parentName="ul">{`A label do botão indica ao usuário o que irá acontecer no clique. Use somente verbos que descrevem a ação, como `}<strong parentName="li">{`Adicionar`}</strong>{` ou `}<strong parentName="li">{`Deletar`}</strong>{`. Utilize o método de capitalização de sentenças (somente a primeira letra maiúscula e nomes próprios) e limite a sentença em até 3 palavras sempre que possível.`}</li>
      <li parentName="ul">{`Em conjuntos de botões, utilize labels específicas como `}<strong parentName="li">{`Salvar`}</strong>{` e `}<strong parentName="li">{`Excluir`}</strong>{`, ao invés de labels genéricas como `}<strong parentName="li">{`Ok`}</strong>{`. Essa prática é útil principalmente quando o usuário está confirmação uma ação.`}</li>
    </ul>
    <h2 {...{
      "id": "tipos-de-botoes",
      "style": {
        "position": "relative"
      }
    }}>{`Tipos de botões`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={190}>Default</th>
      <td>Utilizado para a ação principal da página.</td>
    </tr>
    <tr>
      <th>Ghost button</th>
      <td>Utilizado para ações que não necessitam de atenção primária na página.</td>
    </tr>
    <tr>
      <th>Botões com ícone</th>
      <td>
        Ícones podem ser usados dentro dos botões para comunicar melhor a ação. Os ícones devem estar sempre alinhados
        verticalmente com o texto.
      </td>
    </tr>
    <tr>
      <th>Somente ícone</th>
      <td>Geralmente são associados à um menu dropdown ou ações rápidas.</td>
    </tr>
    <tr>
      <th>Desabilitado</th>
      <td>Utilizado quando o usuário não pode prosseguir até que uma condição seja satisfeita.</td>
    </tr>
    <tr>
      <th>Perigo</th>
      <td>Utilizado quando uma ação tem efeito destrutivo nos dados do usuário (deletar, remover, etc).</td>
    </tr>
    <tr>
      <th>Conjunto de botões</th>
      <td>
        Utilizado quando uma ação possui mais de uma opção. Sempre utilize um botão de uma ação positiva (primário) em
        conjunto com uma ação negativa ou neutra (secundário). Ações negativas devem ser posicionadas à esquerda. Ações
        positivas, à direita.
      </td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "tamanho-de-botoes",
      "style": {
        "position": "relative"
      }
    }}>{`Tamanho de botões`}</h2>
    <Demo src='pages/components/button/ButtonSizes' mdxType="Demo" />
    <h2 {...{
      "id": "tipostemas-de-botoes",
      "style": {
        "position": "relative"
      }
    }}>{`Tipos/temas de botões`}</h2>
    <Demo src='pages/components/button/ButtonSkins' mdxType="Demo" />
    <h2 {...{
      "id": "botoes-com-icones",
      "style": {
        "position": "relative"
      }
    }}>{`Botões com ícones`}</h2>
    <Demo src='pages/components/button/ButtonIcons' mdxType="Demo" />
    <h2 {...{
      "id": "indicador-de-carregando",
      "style": {
        "position": "relative"
      }
    }}>{`Indicador de carregando`}</h2>
    <p>{`O indicador de carregando pode ser ativado/desativado manualmente com o suporte `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`loading`}</code>{` ou automaticamente retornando uma promessa ao suporte `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onClick`}</code>{`.`}</p>
    <Demo src='pages/components/button/ButtonLoading' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      